import React from "react";
import Tilt from "react-parallax-tilt";

import hero from "../../styles/hero.module.css";

function Hero() {
    return (
        <section id="landing" className="container">
            <div className={hero.wrapper}>
                <Tilt tiltReverse={true} tiltMaxAngleX={2} tiltMaxAngleY={2}>
                    <img src="/images/hero-image.png" alt="hero-img" />
                </Tilt>
                <div>
                    <div className={hero["hero-text"]}>
                        <h1>Hammit</h1>
                    </div>
                    <p className={hero["hero-subtext"]}>The Food App</p>
                    <div className="spacer"></div>
                    <div>
                        <a type="button" href="#learn">
                            Learn More <i className="fas fa-angle-down"></i>
                        </a>
                        <a type="button" href="#features">
                            Features <i className="fas fa-angle-down"></i>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;
