import React, { useEffect } from "react";

function Header() {
    var header;
    var body;
    var mobileBackground;

    useEffect(() => {
        header = document.getElementById("header");
        body = document.querySelector("body");
        mobileBackground = document.querySelector(".mobile-background");
    }, []);

    const menuShow = () => {
        header.setAttribute("data-menu", "true");
        body.style.overflowY = "hidden";
        mobileBackground.style.display = "block";
    };

    function menuHide() {
        header.setAttribute("data-menu", "false");
        body.style.overflowY = "scroll";
        mobileBackground.style.display = "none";
    }

    return (
        <header data-menu="false" id="header">
            <div className="mobile-background" onClick={menuHide}></div>
            <nav>
                <div className="brand">
                    <img
                        src="/images/hammit-icon.png"
                        alt="hammit icon"
                        height="20"
                    />
                    <a href="/">Hammit</a>
                </div>
                <div className="content desktop">
                    <ul>
                        <li>
                            <a href="#learn">About</a>
                        </li>
                        <li>
                            <a href="#features">Features</a>
                        </li>
                    </ul>
                </div>
                <div className="content desktop">
                    <a
                        type="button"
                        href="https://appdistribution.firebase.dev/i/e909d23304e7b77b"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Join Beta
                    </a>
                </div>
                <button className="menu-toggle" onClick={menuShow}>
                    <i className="fas fa-bars"></i>
                </button>
            </nav>
            <div className="content mobile">
                <button className="menu-toggle" onClick={menuHide}>
                    <i className="fas fa-xmark"></i>
                </button>
                <a href="#learn" onClick={menuHide}>
                    About
                </a>
                <a href="#features" onClick={menuHide}>
                    Features
                </a>
                <a
                    type="button"
                    href="https://appdistribution.firebase.dev/i/e909d23304e7b77b"
                    target="_blank"
                    rel="noreferrer"
                >
                    Join Beta
                </a>
            </div>
        </header>
    );
}

export default Header;
