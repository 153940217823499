import React from "react";
import Tilt from "react-parallax-tilt";

import learn from "../../styles/learn.module.css";

function About() {
    return (
        <section className={learn.background} id="learn">
            <div className="container">
                <div className={learn.wrapper}>
                    <div>
                        <div className={learn["about-text"]}>
                            <h1>What is it?</h1>
                        </div>
                        <p>
                            Welcome to Hammit, the social media app where food
                            lovers can share their healthy culinary adventures!
                            Our app is exclusively for sharing and discussing
                            healthy food-related photos and experiences. Whether
                            you're a professional chef or just love food, Hammit
                            is the perfect place to share your foodie photos and
                            stories.
                            <br />
                            <br />
                            But we don't stop there! We also have a points
                            system for engaging with the app and sharing
                            content. You also can join groups and compete with
                            other groups.
                        </p>
                    </div>
                    <Tilt
                        tiltReverse={true}
                        tiltMaxAngleX={2}
                        tiltMaxAngleY={2}
                    >
                        <img src="/images/learn-image.png" alt="learn-img" />
                    </Tilt>
                </div>
            </div>
        </section>
    );
}

export default About;
