import React from "react";
import Tilt from "react-parallax-tilt";

import features from "../../styles/features.module.css";

function Features() {
    return (
        <section className={features.background} id="features">
            <div className="container">
                <div className={features.wrapper}>
                    <div className={features.feature}>
                        <Tilt
                            tiltReverse={true}
                            tiltMaxAngleX={7}
                            tiltMaxAngleY={7}
                            scale={1.05}
                        >
                            <img
                                src="/images/feature-image-2.png"
                                alt="feature-img-rank-other-posts"
                            />
                        </Tilt>
                        <div className={features["features-text"]}>
                            <h1>Share what you're currently eating!</h1>
                        </div>
                    </div>
                    <br /> <br /> <br />
                    <div className={features.feature}>
                        <div className={features["features-text"]}>
                            <h1>Rank other user posts!</h1>
                        </div>
                        <Tilt
                            tiltReverse={true}
                            tiltMaxAngleX={7}
                            tiltMaxAngleY={7}
                            scale={1.05}
                        >
                            <img
                                src="/images/feature-image-1.png"
                                alt="feature-img-rank-other-posts"
                            />
                        </Tilt>
                    </div>
                    <br /> <br /> <br />
                    <div className={features.feature}>
                        <Tilt
                            tiltReverse={true}
                            tiltMaxAngleX={7}
                            tiltMaxAngleY={7}
                            scale={1.05}
                        >
                            <img
                                src="/images/feature-image-3.png"
                                alt="feature-img-rank-other-posts"
                            />
                        </Tilt>
                        <div className={features["features-text"]}>
                            <h1>
                                Join groups and compete with others for{" "}
                                <span style={{ color: "var(--yellow)" }}>
                                    #1
                                </span>{" "}
                                in the leaderboard!
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Features;
