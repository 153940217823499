import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";

import Hero from "./components/hero/Hero";
import Learn from "./components/about/Learn";
import Features from "./components/features/Features";
import Join from "./components/join/Join";

function App() {
    return (
        <>
            <Header />
            <main>
                <Hero />
                <Learn />
                <Features />
                <Join />
            </main>
            <Footer />
        </>
    );
}

export default App;
