import React from "react";

function Footer() {
    return (
        <footer className="container">
            <div style={{ position: "relative" }}>
                <span
                    style={{
                        position: "absolute",
                        left: "-1rem",
                        top: "0.5rem",
                    }}
                >
                    &copy;
                </span>
                <h1 style={{ fontFamily: "QType", margin: "0" }}>Hammit</h1>
            </div>
            <h5 style={{ margin: "0", color: "var(--text-sub)" }}>
                The Food App
            </h5>
            <h5 style={{ fontFamily: "Synonym", marginBottom: "0" }}>
                By Rehatbir and Jaasim
            </h5>
        </footer>
    );
}

export default Footer;
