import React from "react";
import Tilt from "react-parallax-tilt";

import join from "../../styles/join.module.css";

function Join() {
    return (
        <section className={join.background} id="join">
            <div className="container">
                <div className={join.wrapper}>
                    <div className={join["join-text"]}>
                        <h1>Get the App!</h1>
                    </div>
                    <p>
                        Want to help test the app? Click on the button below to
                        get access to the download <b>(Android ONLY).</b>
                    </p>
                    <p style={{ margin: "0" }}>
                        If you find any bugs, please mail it at:{" "}
                        <a
                            href="mailto:support@hammit.fun"
                            target="_blank"
                            rel="noreferrer"
                        >
                            support@hammit.fun
                        </a>
                        .
                    </p>
                    <Tilt
                        tiltReverse={true}
                        tiltMaxAngleX={10}
                        tiltMaxAngleY={10}
                        scale={1.2}
                        style={{ marginTop: "2rem" }}
                    >
                        <a
                            type="button"
                            href="https://appdistribution.firebase.dev/i/e909d23304e7b77b"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Join Beta
                        </a>
                    </Tilt>
                </div>
            </div>
        </section>
    );
}

export default Join;
